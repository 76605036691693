<template>
  <v-container fluid >
    <v-row class="hidden-sm-and-down">
      <v-list color="#F2F2F2" style="border-radius:10px;height:100vh !important;">
        <v-list-item-group>
          <h5>
            Dashboard
          </h5>
          <v-list-item
            v-for="(item, i) in menuitems"
            :key="i"
            :to="item.path"
            active-class="highlighted"
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>

    <v-row class="hidden-md-and-up">
      <v-slide-group center-active show-arrows>
        <v-slide-item
          v-for="(item, i) in menuitems"
          :key="i"
          active-class="highlighted"
          center-active
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-btn class="mx-2" depressed rounded :to="item.path">
            {{ item.text }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LogisticMenu",

  components: {},

  data: () => ({
    menuitems: [
      { text: "Overview", path: "/logistics/dashbaord/" + localStorage.getItem("logisticsCompanyId")  },
      { text: "Available Orders", path: "/logistics/orders/" + localStorage.getItem("logisticsCompanyId") },
      // { text: "Track Orders", path: "#" },
      // { text: "Company Profile", path: "#" },
    ],
  }),
};
</script>

<style scoped>
.scanBar {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

.highlighted {
  background-color: whitesmoke !important;
  border-left: 3px solid #fa2d2d;
  /* border-left: 3px solid #7cbf46; */
}
</style>
